import G from "../../../assets/G.svg";
import {useGoogleLogin} from "@react-oauth/google";
import axios from "axios";
import authServices from "../../../services/authServices";
import {useAuth} from "../../../hooks/useAuth";

const FFButton = ({changeLoginProgress}) => {
    const {login} = useAuth();

    const onSuccess = async (res) => {
        await axios.get("https://www.googleapis.com/oauth2/v1/userinfo?access_token=" + res.access_token).then(async response => {
            authServices.loginUser(response.data.family_name + " " + response.data.given_name, response.data.email, res.access_token).then(
                setTimeout(() => {
                    login({
                        token: res.access_token
                    })
                }, 3000)
            );
        })
    };

    const loginGoogleFF = useGoogleLogin({
        onSuccess: tokenResponse => onSuccess(tokenResponse),
        onError: errorResponse => console.log('Belépési hiba történt! (' + errorResponse + ')'),
        ux_mode: 'redirect',
        onNonOAuthError: errorResponse => console.log('Egyéb hiba történt! (' + errorResponse + ')')
    });

    return (
        <button className={'btn-login flex items-center justify-center gap-5'}
                onClick={() => {
                    changeLoginProgress(true);
                    loginGoogleFF();
                }}>
            <img
                src={G}
                alt="Google"/>FF Google SSO {' '}
        </button>
    )
}

export default FFButton;
