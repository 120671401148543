import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./hooks/useAuth";
import {AuthenticatedTemplate, MsalProvider} from "@azure/msal-react";
import {msalConfig} from "./config/authConfig";
import {EventType, PublicClientApplication} from "@azure/msal-browser";
import 'tw-elements';

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.enableAccountStorageEvents();
msalInstance.addEventCallback((message) => {
    if (message.eventType === EventType.ACCOUNT_ADDED) {
        // Update UI with new account
    } else if (message.eventType === EventType.ACCOUNT_REMOVED) {
        // Update UI with account logged out
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
if (window.location.hash !== '') {
    console.log("hash found" + window.location.hash);
} else {
    root.render(
        <BrowserRouter>
            <MsalProvider instance={msalInstance}>
                <AuthProvider>
                    <App/>
                </AuthProvider>
            </MsalProvider>
        </BrowserRouter>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
