import {useAuth} from "../../hooks/useAuth";
import logo from "../../assets/logo_old.svg";
import searchIcon from "../../assets/magnifyingglass.svg";
import open from "../../assets/btn-menu.svg";
import close from "../../assets/btn-menu-close.svg";
import {useMsal} from "@azure/msal-react";
import React, {useState} from "react";
import {useIsAuthenticated} from "@azure/msal-react";


const Nav = () => {
    const {logout} = useAuth();
    const {instance} = useMsal();
    const [navbarOpen, setNavbarOpen] = useState(false);
    const [search, setSearch] = useState('');
    const isAuthenticated = useIsAuthenticated();
    const onChangeSearch = e => {
        const value = e.target.value;
        setSearch(value);

    }

    function handleLogout(instance) {
        if (isAuthenticated) {
            instance.logoutPopup().catch(e => {
                console.error(e);
            }).then(logout)
        } else {
            logout();
        }
    }

    const {user} = useAuth();

    const handleToggle = () => {
        setNavbarOpen(prev => !prev)
    }


    return (
        <nav
            className="py-3 border-b border-navbar">

            <div className={'container mx-auto flex items-center justify-between'}>
                <a href="/"><img src={logo} alt="Gloster Logo" className={'px-8 w-[200px]'}/></a>


                {user &&
                    <>
                        <form action='/result' className={'hidden md:flex'}>
                            <input value={search} onChange={onChangeSearch} type="text" name='search'
                                   className='border border-gray rounded-md px-2.5 py-2 w-[351px] text-xs outline-none focus:border-2 focus:border-brandPrimary'
                                   placeholder='Keresés az oldalon...'/>
                            <button className={'btn-search -translate-x-1'}><img src={searchIcon} alt="Keresés"
                                                                                 className={'w-5 h-5'}/>
                            </button>
                        </form>
                        <button onClick={() => {
                            handleLogout(instance)
                        }}
                                className={'text-buttonPrimary hover:text-buttonHover text-xs font-montserratM mr-4'}>
                            Kijelentkezés
                        </button>
                    </>
                }
            </div>

        </nav>
    );
};

export default Nav;
