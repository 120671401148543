import React from 'react';
import Nav from "../Routing/Nav";


const Layout = (props) => {
    return (
        <div className={'min-h-100'}>
            <Nav/>
            {props.children}
        </div>
    );
};

export default Layout;