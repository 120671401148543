import React from 'react';
import classes from "./Loading.module.scss";

const Loading = () => {
    return (
        <>
            <div
                className={"flex w-full justify-center items-center " + classes['loading']}>
                <div className={classes['dot']}/>
                <div className={classes['dot']}/>
                <div className={classes['dot']}/>
            </div>
        </>
    );
};

export default Loading;