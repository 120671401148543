import axios from "axios";
import authHeader from "./authHeader";

const API_URL = "https://be.videoportal.gloster.hu/api/v1/";

const getVideos = () => {
    return axios.get(API_URL + "videos", {headers: authHeader()})

}

const getVideo = (slug) => {
    return axios.get(API_URL + "video/" + slug, {headers: authHeader()})

}

const getCategories = async () => {
    return await axios.get(API_URL + "categories", {headers: authHeader()})

}
const getYears = async () => {
    return await axios.get(API_URL + "get-years", {headers: authHeader()})
}
const filterVideos = async (filters) => {
    return await axios.get(API_URL + "videos", {params: filters, headers: authHeader()})
}

const userService = {getVideos, getCategories, getVideo, getYears, filterVideos};
export default userService;
