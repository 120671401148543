import axios from "axios";

const API_URL = "https://be.videoportal.gloster.hu/api/v1/";

const getVideo = (slug, deeplink) => {
    return axios.get(API_URL + "video/" + slug, {params: {deeplink: deeplink}})
}

const publicService = {getVideo};
export default publicService;
