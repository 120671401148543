import React, {useEffect, useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import Layout from "../../components/Layout/Layout";
import classes from './Login.module.scss';
import M from "../../assets/sso.svg";
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "../../config/authConfig";
import {useIsAuthenticated} from "@azure/msal-react";
import authServices from "../../services/authServices";
import {useLocation, useNavigate} from "react-router-dom";
import {InteractionRequiredAuthError, InteractionStatus} from "@azure/msal-browser";
import {GoogleOAuthProvider} from "@react-oauth/google";
import MineroButton from "./Components/MineroButton";
import FFButton from "./Components/FFButton";
import Loading from "../../components/Loading/Loading";

const Login = () => {
    const navigate = useNavigate();
    const {login} = useAuth();
    const isAuthenticated = useIsAuthenticated();
    const {instance, inProgress, accounts} = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const [apiData, setApiData] = useState(null);
    const location = useLocation()
    const [isLoginProgress, setIsLoginProgress] = useState(false);

    const changeLoginProgress = (isLoginProgress) => {
        setIsLoginProgress(isLoginProgress);
    };

    useEffect(() => {
        const accessTokenRequest = {
            scopes: ["user.read"],
            account: accounts[0],
        };
        if (!apiData && inProgress === InteractionStatus.None) {
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    // Acquire token silent success
                    let accessToken = accessTokenResponse.accessToken;
                    // Call your API with token
                    authServices.loginUser(accounts[0].name, accounts[0].username, accessToken);
                    login({
                        token: accessToken,
                    });
                })
                .catch(async (error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        await instance.acquireTokenPopup(accessTokenRequest);
                    }

                    //console.log(error);
                });
        }
    }, [instance, accounts, inProgress, apiData]);


    const getToken = () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.accessToken);
            authServices.loginUser(accounts[0].name, accounts[0].username, response.accessToken);
            login({
                token: response.accessToken,
            });
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenPopup(request);
            }
            console.log(error);
        });
    }

    function handleLogin(instance) {
        instance.loginPopup().catch(e => {
            console.error(e);
        });
    }

    return (
        <Layout>
            <div className={"flex w-full items-center justify-center " + classes["background"]}>
                {isLoginProgress ?
                    <div onClick={() => setIsLoginProgress(false)}
                         className={"flex w-full h-full absolute top-0 items-center justify-center z-40 backdrop-brightness-50"}>
                        <div className={"lg:col-span-2 xl:col-span-3"}><Loading/></div>
                    </div> : <></>}
                <div
                    className={"bg-white px-8 py-10 rounded-md md:w-[540px] mx-8"}>
                    <div className="text-center">
                        <h1 className="font-header font-black text-2xl text-black mb-1">GLOSTER VIDEO PORTAL</h1>
                        <p className={'pb-5 border-b-2 border-brandSecondary mb-5 md:mb-12 text-xs text-black font-montserratM text-black mt-2'}>Jelentkezz
                            be Google vagy Microsoft fiókod segítségével!</p>
                        <div className={'flex flex-col gap-8 mb-5 md:mb-8 items-center'}>
                            <button className={'btn-login  items-center justify-center gap-5 flex'}
                                    onClick={() => handleLogin(instance)}><img src={M}
                                                                               alt="Microsoft"/>Belépés
                                Microsoft SSO-val
                            </button>

                            <GoogleOAuthProvider
                                clientId="725798464705-feiatgii01khof21sag2gd3in595eigh.apps.googleusercontent.com">
                                <MineroButton changeLoginProgress={changeLoginProgress}/>
                            </GoogleOAuthProvider>

                            <GoogleOAuthProvider
                                clientId="811292754945-i94athn7toiue2g6hfsksi3a606mgk6e.apps.googleusercontent.com">
                                <FFButton changeLoginProgress={changeLoginProgress}/>
                            </GoogleOAuthProvider>

                        </div>
                        <p className={'text-[10px] md:text-xs'}>A Gloster Video Portal egy zárt oldal, amely kizárólag
                            az arra
                            jogosultak hozzáférésével érhető el. Amennyiben szeretne hozzáférést, keresse fel
                            ügyfélszolgálatunkat.</p>
                    </div>

                </div>
            </div>
        </Layout>
    );
};

export default Login;
