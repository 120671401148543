export const msalConfig = {
    auth: {
        clientId: "6b0b2202-d6a5-4ae0-94d1-c3e529e05714",
        authority: "https://login.microsoftonline.com/42358ebc-348f-4579-813b-68600a89019b", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: "https://videoportal.gloster.hu",
        authorization_user_agent: "WEBVIEW"

    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/"
};
