import axios from "axios";
import authHeader from "./authHeader";

const API_URL = "https://be.videoportal.gloster.hu/api/v1/";

const loginUser = (name, email, token) => {
    return axios.post(API_URL + "user-login", {name, email, token})
}

const getUserData = () => {
    return axios.get(API_URL + "user-data", {headers: authHeader()})
}

export default {loginUser, getUserData}
