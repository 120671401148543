import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import {Navigate, useNavigate} from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import classes from "./Home.module.scss";
import userService from "../../services/userServices";
import {format} from 'date-fns';
import Loading from "../../components/Loading/Loading";
import searchIcon from "../../assets/magnifyingglass.svg";
import useFetch from "../../hooks/useFetch";

const Home = () => {
    const {user} = useAuth();
    const {logout} = useAuth();
    const navigate = useNavigate();
    const [query, setFilters] = useState({
        'search': '',
        'categories': [],
        'year': '',
        'order': '',
    });

    const [page, setPage] = useState(1);
    const {list, loading} = useFetch(query, page);
    const [videos, setVideos] = useState(list);
    const [years, setYears] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [search, setSearch] = useState('');
    const loader = useRef(null);

    useEffect(() => {
        setPage(prev => 1)
    }, [query]);

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setPage(prev => prev += 1);
        }
    }, []);


    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "100px",
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
    }, [handleObserver]);


    useEffect(() => {
        if (localStorage.getItem('from')) {
            navigate(localStorage.getItem('from'))
        }
        userService.getCategories().then(result => {
            setCategories(result.data.categories);
        }, error => {
            error.response.data.message === 'Unauthenticated' && logout()
        });
        userService.getYears().then(result => {
            setYears(result.data.years);
        }, error => {
            error.response.data.message === 'Unauthenticated' && logout()
        });
    }, []);


    const getVideos = () => {
        return list.map(video => {
            const dt = format(new Date(video.published_at.replace(/-/g, "/")), 'yyyy. MM. dd.');
            return <a href={"/video/" + video.url_slug}
                      className={"flex flex-col justify-between hover:scale-105 transition-all duration-500"}><img
                src={video.thumbnail_url}
                alt={video.title}
                className={"mb-2 lg:max-h-[200px] object-cover"}/>
                <div className={"flex flex-col justify-between"}>
                    <h3 className={"text-lg lg:text-sm text-black font-bold border-l-[5px] border-brandSecondary mt-2 pl-1 " + classes["line-clamp"]}>{video.title}</h3>
                    <p className={"text-xs font-montserratM my-2 " + classes["line-clamp-3"]}>{video.description}</p>
                    <div className={"flex flex-row justify-between mb-5"}><p className={"text-xs italic"}>Előadó: <span
                        className={"font-bold"}>{video.presenter}</span></p> <p className={"text-xs italic"}>{dt}</p>
                    </div>
                </div>
            </a>
        })
    }

    const onChangeSearch = e => {
        const value = e.target.value;
        setSearch(value);

    }

    const getCategories = (identifier) => {
        return categories.map(category => {
            return <div className={"mb-2"}>
                <input type="checkbox" id={category.id + identifier}
                       className={'accent-brandPrimary peer hidden'} onChange={(e) => {
                    const categoryArray = query.categories || [];
                    if (e.target.checked) {
                        categoryArray.push(category.id);
                    } else {
                        const index = categoryArray.indexOf(category.id);
                        if (index > -1) {
                            categoryArray.splice(index, 1);
                        }
                    }
                    setFilters(prevState => ({
                        ...prevState,
                        categories: categoryArray
                    }));
                }}/>
                <label htmlFor={category.id + identifier}
                       className={"text-xs peer-checked:bg-brandPrimary peer-checked:text-white rounded-full bg-lightgray border border-brandPrimary py-1 px-3 text-xs font-montserratM transition-all"}> {category.name}</label>
            </div>
        })
    }

    const getYears = () => {
        return years.map(year => {
            return <option value={year}>{year}</option>
        })
    }

    const yearSelect = e => {
        const value = e.target.value;
        setFilters(prevState => ({
            ...prevState,
            year: value
        }));
    }

    const orderSelect = e => {
        const value = e.target.value;
        setFilters(prevState => ({
            ...prevState,
            order: value
        }));
    }

    if (!user) {
        return <Navigate to="/login"/>
            ;
    }


    return (
        <Layout>
            <div className={"flex w-full " + classes["background"]}>
                <div className={"container mx-auto px-10 mt-11"}>
                    <div className={'md:hidden mb-8'}>
                        <h3 className={'uppercase text-brandPrimary font-bold text-lg mb-2'}>Keresés</h3>
                        <form action='/result' className={'md:hidden flex mb-5'}>
                            <input value={search} onChange={onChangeSearch} type="text" name='search'
                                   className='border border-gray rounded-md px-2.5 py-2 w-4/5 text-sm font-montserratM outline-none focus:border-brandPrimary'
                                   placeholder='Keresés az oldalon...'/>
                            <button className={'btn-search -translate-x-1'}><img src={searchIcon} alt="Keresés"
                                                                                 className={'w-5 h-5'}/>
                            </button>
                        </form>
                    </div>

                    <div className={"flex flex-col md:grid md:grid-cols-[1fr_5fr]"}>
                        <div className={"md:mr-10 min-w-[161px]"}>
                            <div className={"block md:hidden"}>
                                <div className="accordion accordion-flush" id="accordionExample">
                                    <div className="accordion-item border-t-0 border-l-0 border-r-0 rounded-none ">
                                        <h2 id="Szűrők" className={"accordion-header"}>
                                            <button
                                                className={"accordion-button  uppercase text-brandPrimary font-bold text-lg mb-8 relative flex items-center w-full text-left border-0 rounded-none transition focus:outline-none " + classes['accordion-button']}
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="false"
                                                aria-controls="collapseOne">
                                                Szűrők
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse"
                                             aria-labelledby="headingOne"
                                             data-bs-parent="#accordionExample">
                                            <div className="accordion-body pb-6">
                                                <h3 className={'uppercase text-brandPrimary font-bold text-sm mb-1'}>Rendezés</h3>
                                                <select name="cars" id="cars" onChange={orderSelect}
                                                        className={'w-full text-xs font-montserratM text-black bg-white border border-lightgray rounded-md px-2 h-7 mb-5'}>
                                                    <option value="descDate">Legújabb videó</option>
                                                    <option value="ascDate">Legrégebbi videó</option>
                                                    <option value="a-z">A-Z</option>
                                                    <option value="z-a">Z-A</option>
                                                </select>
                                                <h3 className={'uppercase text-brandPrimary font-bold text-sm mb-1 pt-5 border-t-2 border-brandSecondary'}>Kategóriák</h3>
                                                <div className={'flex flex-col mb-5'}>
                                                    {getCategories('m')}
                                                </div>
                                                <h3 className={'uppercase text-brandPrimary font-bold text-sm mb-2 pt-5 border-t-2 border-brandSecondary'}>Év</h3>
                                                <div className={'border-b-2 border-brandSecondary'}>
                                                    <select name="cars" id="cars" onChange={yearSelect}
                                                            className={'w-full text-xs font-montserratM text-black bg-white border border-lightgray rounded-md px-2 h-7 mb-5'}>
                                                        <option value="">Összes</option>
                                                        {getYears()}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"hidden md:flex md:flex-col"}>
                                <h3 className={'uppercase text-brandPrimary font-bold text-sm mb-1'}>Rendezés</h3>
                                <select name="cars" id="cars" onChange={orderSelect}
                                        className={'w-full text-xs font-montserratM text-black bg-white border border-lightgray rounded-md px-2 h-7 mb-5'}>
                                    <option value="descDate">Legújabb videó</option>
                                    <option value="ascDate">Legrégebbi videó</option>
                                    <option value="a-z">A-Z</option>
                                    <option value="z-a">Z-A</option>
                                </select>
                                <h3 className={'uppercase text-brandPrimary font-bold text-sm mb-1 pt-5 border-t-2 border-brandSecondary'}>Kategóriák</h3>
                                <div className={'flex flex-col mb-5'}>
                                    {getCategories('d')}
                                </div>
                                <h3 className={'uppercase text-brandPrimary font-bold text-sm mb-2 pt-5 border-t-2 border-brandSecondary'}>Év</h3>
                                <div className={'border-b-2 border-brandSecondary'}>
                                    <select name="cars" id="cars" onChange={yearSelect}
                                            className={'w-full text-xs font-montserratM text-black bg-white border border-lightgray rounded-md px-2 h-7 mb-5'}>
                                        <option value="">Összes</option>
                                        {getYears()}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 className={"uppercase text-4xl leading-10 font-sans font-bold text-black mt-4"}
                                id={'top'}>Videók</h1>
                            <div
                                className={"flex flex-col lg:grid lg:grid-cols-2 lg:gap-6 xl:grid xl:grid-cols-3 xl:gap-6 relative"}>
                                <div
                                    className={"lg:col-span-2 xl:col-span-3 pb-4 border-b-2 border-brandSecondary mb-5"}>

                                </div>
                                {isLoading ? <div className={"lg:col-span-2 xl:col-span-3"}><Loading/></div> : <>
                                    {getVideos()}
                                </>}
                                {loading && <div className={"lg:col-span-2 xl:col-span-3"}><Loading/></div>}
                                <div className={'absolute bottom-0'} ref={loader}/>

                                <a href={'#top'}
                                   className="fixed bottom-5 right-5 bg-brandPrimary px-3 pt-2 pb-5 flex items-center rounded-full text-[40px] leading-[20px] shadow-lg hover:bg-brandSecondary">&#9650;</a>


                            </div>
                        </div>

                    </div>


                </div>


            </div>
        </Layout>

    )
};

export default Home;
