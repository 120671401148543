import React from 'react';
import Layout from "../../components/Layout/Layout";
import classes from "./NotFound.module.scss";

const NotFound = () => {
    return (
        <Layout>
            <div className={"flex w-full " + classes["background"]}>
                <div className={"container mx-auto px-10 mt-11"}>
                    <div className={"flex flex-col justify-center items-center h-[70%] text-center"}>
                        <h1 className={"uppercase text-2xl leading-6 font-sans font-bold text-black mb-4  pb-8 border-b-2 border-brandSecondary"}>A
                            keresett
                            oldal
                            nem található!</h1>
                        <p className={"text-black font-montserratM text-base"}>A link nem megfelelő vagy a keresett
                            oldalt már eltávolították.</p>
                        <p className={"text-black font-montserratM text-base"}>Térj
                            vissza a főoldalra és nézz körül az elérhető videók között!</p>
                        <div className={"mt-8"}>
                            <a href="/" className={"btn-primary"}>Vissza a főoldalra</a>
                        </div>
                    </div>


                </div>
            </div>
        </Layout>
    );
};

export default NotFound;