import React, {useEffect, useState} from 'react';
import Layout from "../../components/Layout/Layout";
import {Navigate, useParams, useSearchParams} from "react-router-dom";
import {format} from "date-fns";
import {useNavigate} from 'react-router-dom';
import Loading from "../../components/Loading/Loading";
import classes from "./Deeplink.module.scss";
import publicService from "../../services/publicServices";
import useMetaTags from 'react-metatags-hook';

const Deeplink = () => {

        const [video, setVideo] = useState({});
        const [copy, setCopy] = useState({text: 'Publikus link megosztása', isCopied: false});
        const [isLoading, setIsLoading] = useState(false);
        const videoSlug = useParams().slug;
        const [videoParams] = useSearchParams();
        const navigate = useNavigate();
        const [videoDate, setVideoDate] = useState('');

        useEffect(() => {
            setIsLoading(true);
            publicService.getVideo(videoSlug, videoParams.get('accesstoken')).then(result => {
                setVideo(result.data.video);
                setIsLoading(false);
                setVideoDate(format(new Date(result.data.video.published_at.replace(/-/g, "/")), 'yyyy. MM. dd.'))
            }, error => {
                navigate("/404", {replace: true});
            })
        }, []);

        useEffect(() => {
            setTimeout(() => {
                setCopy({text: 'Publikus link megosztása', isCopied: false});
            }, 1500)
        }, [copy.isCopied === true])

        useMetaTags({
            title: `Gloster Videóportál - ${video.title}`,
            description: `${video.description}`,
            openGraph: {
                title: `Gloster Videóportál - ${video.title}`,
                description: `${video.description}`,
                image: `${video.thumbnail_url}`,
                'site_name': 'Gloster Videóportál'
            }
        }, [video]);

        return (
            <Layout>
                <div className={"flex w-full " + classes["background"]}>{
                    isLoading ? <Loading/> : <>
                        <div className={"container mx-auto px-10 mt-11"}>
                            <div className={"flex flex-row justify-end mb-5"}>
                            </div>
                            <div className={"mb-8 flex justify-center"}>
                                <iframe src={video.embed_code} width="100%"
                                        className={'h-[calc(100vw*0.5625)] max-h-[400px]'}
                                        height="400" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                                        allowFullScreen/>

                            </div>
                            {video.deeplink != null && <div className={'flex justify-center my-5'}>
                                <button onClick={() => {
                                    navigator.clipboard.writeText('https://videoportal.gloster.hu/public/video/' + video.url_slug + '?accesstoken=' + video.deeplink);
                                    setCopy({text: 'Vágólapra másolva!', isCopied: true})
                                }}
                                        className={"btn-primary mt-4"}>{copy.text}
                                </button>
                            </div>}
                            <h1 className={"text-lg lg:text-2xl text-black font-bold mb-auto border-l-[5px] border-brandSecondary pl-1 mb-3"}>{video.title}</h1>
                            <div className={"font-montserratM text-sm mb-4"}>{video.description}</div>
                            <div className={"italic text-sm font-montserratM mb-4"}>Előadó: <span
                                className={"font-bold"}>{video.presenter}</span>
                            </div>
                            <div className={"font-montserratM text-sm italic mb-4"}>{videoDate}</div>
                        </div>
                    </>
                }


                </div>
            </Layout>
        );
    }
;

export default Deeplink;
